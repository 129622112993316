jQuery(document).ready(function() {
    setCurrency();
    var currency = 'cad';
    var usd_exchange_rate;
    jQuery('.js-package').on('click', function() {
        var thisElement=$(this);
        var packageId=thisElement.attr('data-package-id');
        var activePlan=thisElement.attr("data-feature-active-plan-id");

        $parent                 = thisElement.parent();
        $parents                = $parent.parent();
        package                 = thisElement.data('package');
        var thisHasDisclaimer    = thisElement.attr('data-has-disclaimer');
        disableAllInputs($parent);
        resetButtonPackageState($parent);
        jQuery('.js-usage-1 .js-input-estimation').prop('disabled', false);
        $parents.find('.js-usage-1, .js-usage-2, .js-usage-3').removeClass('active');
        $parents.find('.js-title1, .js-title-2, .js-title-3').removeClass('active');

        if(package == 1) {
            $parent.find('.js-detail').addClass('hide');
        } else if(package == 2) {
            $parents.find('.js-usage-1').addClass('active');
            $parents.find('.js-title-1').addClass('active');
            $parents.find('.js-usage-1 input').prop('disabled', false);
            $parent.find('.js-detail').removeClass('hide');
        } else if(package == 3) {
            $parents.find('.js-usage-1, .js-usage-2').addClass('active');
            $parents.find('.js-title-1, .js-title-2').addClass('active');
            $parents.find('.js-usage-1 input, .js-usage-2 input').prop('disabled', false);
            $parent.find('.js-detail').removeClass('hide');
        } else if(package == 4) {
            $parents.find('.js-usage-1, .js-usage-2, .js-usage-3').addClass('active');
            $parents.find('.js-title-1, .js-title-2, .js-title-3').addClass('active');
            $parents.find('.js-usage-1 input, .js-usage-2 input, .js-usage-3 input').prop('disabled', false);
            $parent.find('.js-detail').removeClass('hide');
        }
        if(thisHasDisclaimer=='true'){
            $parent.find('.js-detail').removeClass('hide');
        } else if(thisHasDisclaimer=='false'){
            $parent.find('.js-detail').addClass('hide');
        }
        jQuery(this).find('input').prop('checked', true);
        jQuery(this).addClass('active'); 
        base_fee_val = jQuery(this).find('.js-start-fee').data('base-' + currency.toLowerCase());
        getBaseFee(base_fee_val, $parents);
        getTotal($parents);
        checkFeatures(package, $parents);
        setIntro(package, $parents);
        setBlocUsage(package, $parents);

         if(package == 1 && jQuery('.js-tab[data-persona="supplier"]').hasClass('active')) {
            jQuery('.js-plan[data-plan="1"]').addClass('active');
            jQuery('.fa-check-square[data-plan-feature="1"]').addClass('active');
            jQuery('.js-plan[data-plan="2"]').removeClass('active');
            $parent.find('.js-detail').addClass('hide');
            jQuery('.js-features-1').removeClass('hide');
            jQuery('.js-features-2, .js-row-usage').addClass('hide');
        } else if(jQuery('.js-tab[data-persona="supplier"]').hasClass('active')) {
            jQuery('.js-features-1').addClass('hide');
            jQuery('.js-features-2, .js-row-usage').removeClass('hide');
            //jQuery('.js-usage-1 .js-input-estimation').prop('disabled', true);
            jQuery('.js-usage-1 .js-calculator').css('opacity', 0.2);
        }
        if(packageId==5){
            $('[data-plan='+activePlan+']').click();
        }
    });
    jQuery('.js-input-estimation').on('keyup mouseup', function() {
        estimation_val = jQuery(this).val();
        parents = jQuery(this).closest('table');
        if(estimation_val != "") {
            multiplicator = jQuery(this).next().data('multiplicator');
            jQuery(this).next().find('.js-subtotal').text('$'+(estimation_val*multiplicator).toFixed(2));
            getTotal(parents);
        } else {
            //jQuery(this).val(0);
            jQuery(this).next().find('.js-subtotal').text('$0');
        }
    });
    jQuery('.js-tab').on('click', function() {
        persona = jQuery(this).data('persona');
        jQuery('.js-tab').removeClass('active');
        jQuery('.js-retailer, .js-supplier').addClass('hide');
        jQuery('.js-tab[data-persona="' + persona + '"]').addClass('active');
        jQuery('.js-' + persona).removeClass('hide').hide().fadeIn();
        //jQuery(document).foundation('equalizer','reflow');
    });
    jQuery('.js-plan').on('click', function() { 
        jQuery('.js-plan, .fa-check-square[data-plan-feature="2"]').removeClass('active'); 
        jQuery('.fa-check-square[data-plan-feature="1"]').addClass('active');
        jQuery(this).addClass('active');
        $('[data-feature-active-plan-id]').attr('data-feature-active-plan-id',jQuery(this).data('plan'));
        if(jQuery(this).data('plan') == 2) {
            jQuery('.fa-check-square[data-plan-feature="2"]').addClass('active');
            jQuery('.js-base-fee').text('$3,600');
            jQuery('.js-month-fee').text('$300');
        } else {
            jQuery('.js-base-fee').text('$0');
            jQuery('.js-month-fee').text('$0');
        } 
    });
    jQuery('.js-currency').on('click', function() {
        $parents = jQuery(this).parent().parent().parent();
        jQuery('.js-currency').removeClass('active');
        currency = jQuery(this).text();
        currency_lower = jQuery(this).text().toLowerCase();
        jQuery('.js-currency:contains(' + currency + ')').addClass('active');
        convertCurrencyBaseFee(currency_lower, $parents);
        convertCurrencyTotalMonthlyFee(currency_lower, $parents);
        var activeTab=$("[data-persona].active").attr('data-persona');
        var activeFeature=$('.container.js-'+activeTab).find("[data-package].active");
        var activeFeatureId=activeFeature.attr('data-package-id');
        var activePlan=activeFeature.attr("data-feature-active-plan-id");
        $('.container.js-'+activeTab).find("[data-package].active").click();
        activeFeature.click();
        if(activeFeatureId=='5') $('[data-plan='+activePlan+']').click();
    });
    function getTotal(parents) {
        var total = 0;
        month_fee_val = jQuery(parents).find('.js-package.active').find('.js-start-fee').data('month-' + currency.toLowerCase());
        jQuery(parents).find('.js-month-fee').text('');
        jQuery(parents).find('.js-subtotal').each(function() {
            subtotal_val = jQuery(this).text();
            if(subtotal_val != "" && jQuery(this).parent().parent().parent().hasClass('active')) {
               subtotal_val = subtotal_val.replace('$','');
               total  += parseFloat(subtotal_val);
            }
            if(parseFloat(subtotal_val) && $(this).parent().data('addfee')) total+= $(this).parent().data('addfee');
        });
        total = total + month_fee_val;
        jQuery(parents).find('.js-month-fee').text('$'+total.toFixed(2));
    }
    function getBaseFee(base_fee_val, parents) {
        jQuery(parents).find('.js-base-fee').text('');
        if (base_fee_val == undefined) {
            base_fee_val = 0; 
        }
        jQuery(parents).find('.js-base-fee').text('$'+base_fee_val);
    }
    function resetButtonPackageState(parent) {
        jQuery(parent).find('.js-package').removeClass('active');
    }
    function disableAllInputs(parent) {
        jQuery(parent).find('section .js-package input').prop('disabled', true);
    }
    function disableAllFeatures() {
        jQuery('.fa-check-square').removeClass('active');
    }
    function convertCurrencyBaseFee(currency, parents) {
        jQuery('.js-start-fee').each(function() {
            start_fee_val = jQuery(this).data('month-' + currency);
            jQuery(this).text(Math.round(start_fee_val));
        });
        base_fee_val = jQuery(parents).find('.js-package.active').find('.js-start-fee').data('base-'+ currency.toLowerCase()); 
        if(base_fee_val!='undefined'&&base_fee_val!='Nan'&base_fee_val!=''){
            //jQuery(parents).find('.js-base-fee').text('');
            jQuery(parents).find('.js-base-fee').text('$'+base_fee_val);
        } else {
            jQuery(parents).find('.js-base-fee').text('$0');
        }

    }
    function setCurrency() {
         jQuery('.js-start-fee').each(function() {
            start_fee_cad_val = jQuery(this).data('month-cad');
            jQuery(this).text(start_fee_cad_val);
        });
    }
    function convertCurrencyTotalMonthlyFee(currency, parents) {
        month_total_fee_val =  jQuery(parents).find('.js-package.active').find('.js-start-fee').data('month-'+ currency.toLowerCase());
        jQuery(parents).find('.js-subtotal').each( function() {
            subtotal_val = jQuery(this).text();
            subtotal = parseFloat(subtotal_val);console.log(subtotal);
            if(subtotal != NaN) {
                month_total_fee_val = month_total_fee_val + subtotal;
            }
        });
        jQuery(parents).find('.js-month-fee').text(month_total_fee_val.toFixed(2));
    }
    function checkFeatures(package, parents) {
        jQuery(parents).find('.fa-check-square').removeClass('active');
        for(x=1;x<=package;x++) {
            jQuery(parents).find('.fa-check-square[data-package-feature="' + x + '"]').addClass('active');
        }
    }
    function setIntro(package, parents) {
        jQuery(parents).find('.js-intro').addClass('hide');
        jQuery(parents).find('.js-intro[data-package-intro="' + package + '"]').removeClass('hide');
    }
    function setBlocUsage(package, parents) {
        // jQuery(parents).find('.js-usage-free, .js-usage-package').addClass('hide');
        if(package == 1) {
            //jQuery(parents).find('.js-usage-free').removeClass('hide');
        } else {
            jQuery(parents).find('.js-usage-package').removeClass('hide'); 
        }
    }
    //Exchange rate from API
     /*function setCurrency() {
         jQuery.getJSON('https://free.currencyconverterapi.com/api/v5/convert?q=CAD_USD&compact=y', function(data) {
            usd_exchange_rate = data.CAD_USD.val;
            jQuery('.js-start-fee').each(function() {
                //convert starting monthly fee
                start_fee_cad_val = jQuery(this).data('month-cad');
                start_fee_usd_val = start_fee_cad_val*usd_exchange_rate;
                jQuery(this).data('month-usd', start_fee_usd_val);
                jQuery(this).text(start_fee_cad_val);
                //convert base fee
                base_fee_cad_val = jQuery(this).data('base-cad');
                base_fee_usd_val = base_fee_cad_val*usd_exchange_rate;
                jQuery(this).data('base-usd', Math.round(base_fee_usd_val)); 
            });
        });
    }
    function convertCurrencyTotalMonthlyFee(currency) {
        month_total_fee_val = jQuery('.js-month-fee').text();
        if(currency == 'cad') {
           month_total_fee_val = parseInt(month_total_fee_val)/usd_exchange_rate;
        } else {
           month_total_fee_val = parseInt(month_total_fee_val)*usd_exchange_rate; 
        }
        jQuery('.js-month-fee').text(month_total_fee_val.toFixed(2)); 
    }*/
    $('.js-package input:checked').parent().trigger('click')
}); 